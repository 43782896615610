import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import { auth } from './auth-module'
import {FTemplateRegModule} from "@/store/f-template-reg-module";
import {FtRegistrasiModule} from "@/store/ft-registrasi-module";
import { registration } from '@/store/account-registration/registration-module'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        FTemplateRegModule,
        FtRegistrasiModule,
        registration
    },
    plugins: [createPersistedState()],
})
