<template>
  <div class="dashboard">
    <HomeMain></HomeMain>
    <AppFooter/>
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
import HomeMain from "@/components/public-home/HomeMain.vue";

export default {
  components: {
    HomeMain,
    AppFooter
  },
  data () {
    return {
    }
  },


}
</script>

<style>
  body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .bg-light {
    background: #FCFBFF;
  }

  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-head {
    margin: 0 0 30px;
  }

  .section-title {
    font-size: 1.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 15px;
  }

  .section-meta {
    font-size: 1rem;
    line-height: 25px;
    font-weight: 300;
    color: #777;
  }

  .section-body {
    font-weight: 300;
  }
</style>
