<template>
  <div class="bg-light">
    <v-card elevation="0">
     <HomeMainCarousel
      v-if="! currentUser"
     ></HomeMainCarousel>

      <div :class="$vuetify.breakpoint.smAndDown ? 'ml-2 ' : 'ml-16' " class="mt-10" style="position: absolute; z-index: 4; top: 50px; left: 0;" v-if="! currentUserAdmin">
        <v-card-title class="text-h4 orange--text headline font-weight-black">
          SILAYAK
        </v-card-title>
        <v-card-subtitle class="white--text">
          <v-row >
            <v-col cols="10" sm="10" md="5">
              Sistem Informasi Layanan Kepegawaian Kota Palangkaraya adalah sebuah platform digital yang dirancang untuk mendukung pengelolaan data kepegawaian secara efisien, modern, dan terintegrasi.
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-actions>
          <v-btn
              v-if="!currentUserAsn"
              @click="toLogin"
              width="150px"
              color="success"
              dark>
            Sign In
          </v-btn>
<!--          <v-btn-->
<!--              v-if="!currentUserAsn"-->
<!--              @click="toRegister"-->
<!--              class="ml-2"-->
<!--              width="150px"-->
<!--              color="primary"-->
<!--              dark>-->
<!--            Sign Up-->
<!--          </v-btn>-->
          <v-btn
              disabled
              v-if="!currentUserAsn"
              @click="toRegister"
              class="ml-2"
              width="150px"
              color="primary"
              dark>
            Sign Up
          </v-btn>

        </v-card-actions>

      </div>

    </v-card>

    <LayananTable
     :itemsFTemplateReg="itemsFTemplateReg">
    </LayananTable>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import HomeMainCarousel from "@/components/public-home/HomeMainCarousel.vue";
import ERole from "@/models/e-role";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import LayananTable from "@/components/public-home/LayananTable.vue";

export default {
  name: "HomeMain",
  props: {
  },
  components: {
    LayananTable,
    HomeMainCarousel,
  },
  data() {
    return {
      showInfo: true,
      searchShow: false,
      infoDialogMessage: "",

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 9,
      pageSizes: [9, 25, 50, 150, 500],

      search: "",

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      itemsFDivision: [],

      itemsFTemplateReg: []
    };
  },
  computed: {
    currentUserAsn(){
      if(this.$store.state.auth.user){
        return !!this.$store.state.auth.user.roles.includes(ERole.ROLE_USER);
      } else {
        return false
      }
    },
    currentUserAdmin(){
      if(this.$store.state.auth.user){
        return !!this.$store.state.auth.user.roles.includes(ERole.ROLE_ADMIN);
      } else {
        return false
      }
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    toRegister() {
      this.$router.push("/select-account-type");
    },

    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },
    fetchFTemplateReg(){
      FTemplateRegService.getAllFTemplateRegPublic().then(
          (response) => {
            this.itemsFTemplateReg = response.data.filter(x => x.statusActive)
          }
      )
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return require("@/assets/images/no_image_available.jpeg");
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return require("@/assets/images/no_image_available.jpeg");
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
  mounted() {
    this.fetchFTemplateReg();
  },
};
</script>

<style scoped>
.bg-light {
  background-color: #ece3f3;
}

</style>