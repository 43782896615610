
export const FTemplateRegModule = {
    namespaced: true,
    state: {
      itemModified: Object,
      itemDefault: Object,
      itemDetilModified: Object,
      itemDetilDefault: Object,
      formMode: String,
      formModeFTemplateRegDokumen: String,
      itemsFDivision: [],
      itemsFJenisDokumen: [],
      itemsFTemplateRegGroup: [],
    },
    actions: {
      updateItemModified({commit}, payload){
        commit('mutateItemModified', payload)
      },
      updateItemDefault({commit}, payload){
        commit('mutateItemDefault', payload)
      },
      updateItemDetilModified({commit}, payload){
        commit('mutateItemDetilModified', payload)
      },
      updateItemDetilDefault({commit}, payload){
        commit('mutateItemDetilDefault', payload)
      },
      updateFormMode({commit}, payload){
        commit('mutateFormMode', payload)
      },
      updateFormModeFTemplateRegDokumen({commit}, payload){
        commit('mutateFormModeFTemplateRegDokumen', payload)
      },
      updateItemsFDivision({commit}, payload){
        commit('mutateItemsFDivision', payload)
      },
      updateItemsFJenisDokumen({commit}, payload){
        commit('mutateItemsFJenisDokumen', payload)
      },

      updateItemsFTemplateRegGroup({commit}, payload){
        commit('mutateItemsFTemplateRegGroup', payload)
      },

    },
  
    mutations: {
      mutateItemModified(state, payLoad){
        state.itemModified = payLoad
      },
      mutateItemDefault(state, payLoad){
        state.itemDefault = payLoad
      },
      mutateItemDetilModified(state, payLoad){
        state.itemDetilModified = payLoad
      },
      mutateItemDetilDefault(state, payLoad){
        state.itemDetilDefault = payLoad
      },
      mutateFormMode(state, payLoad){
        state.formMode = payLoad
      },
      mutateFormModeFTemplateRegDokumen(state, payLoad){
        state.formModeFTemplateRegDokumen = payLoad
      },
      mutateItemsFDivision(state, payLoad){
        state.itemsFDivision = payLoad
      },
      mutateItemsFJenisDokumen(state, payLoad){
        state.itemsFJenisDokumen = payLoad
      },
      mutateItemsFTemplateRegGroup(state, payLoad){
        state.itemsFTemplateRegGroup = payLoad
      },

    },
  
  }
  