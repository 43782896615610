<template>
  <v-card>
    <v-carousel
        cycle
        interval="2500"
        height="450"
        hide-delimiter-background
        show-arrows-on-hover
    >
      <v-carousel-item v-for="(item, index) in itemsCarousel" :key="index">
        <v-responsive>
          <v-img
              gradient="to right, rgba(0,0,0,.9), rgba(0,0,0,0.05)"
              :src="lookupImageUrl(item)"
              min-height="450px">
            <v-spacer></v-spacer>


            <v-spacer></v-spacer>

          </v-img>
        </v-responsive>
      </v-carousel-item>
    </v-carousel>

  </v-card>
</template>

<script>
import  FDinCarouselService  from '@/services/apiservices/f-din-carousel-service'
import FileService from "@/services/apiservices/file-service";
export default {
  name: 'HomeMainCarousel',
  data() {
    return {
      itemsCarousel: [],
    }
  },
  computed:{
  },
  methods: {
    fetchFDinCarousel() {
      FDinCarouselService.getAllFDinCarouselByTypePublic(1)
        .then(response => {
          this.itemsCarousel = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },

    lookupImageUrl(item) {
      if (item.avatarImage !== undefined && item.avatarImage !== "") {
        return FileService.image_url_medium(item.avatarImage);
      } else {
        return require("@/assets/images/no_image_available.jpeg");
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage !== undefined && item.avatarImage !== "") {
        return FileService.image_url_verylow(item.avatarImage);
      } else {
        return require("@/assets/images/no_image_available.jpeg")
      }
    },

  },
  created() {
    this.fetchFDinCarousel()
  }
}

</script>

<style scoped>

</style>