<template>
  <div class="ma-4">
    <v-row>
      <v-col>
        <span class="font-weight-black subtitle-1">DASHBOARD</span>
      </v-col>
    </v-row>

  </div>
</template>

<script>
// import ApiSimpegService from "@/services/apiservices-ext/api-simpeg-service";

export default {
  name: "Dashboard",
  methods: {
  },
  mounted() {
    console.log("=========== REKAPILUTASI LAYANAN ===========");

    // ApiSimpegService.getPostSimpegDataPegawai("198502062010011008").then(
    //     (response) => {
    //       console.log("Masuk sini")
    //       console.log(JSON.stringify(response.data));
    //     },
    //     (err) => {
    //       console.log("Tidak bisa masuk sini")
    //       console.log(err.data);
    //     }
    // );
    //
    // ApiSimpegService.getSimpegToken().then(
    //     (response) => {
    //       console.log(JSON.stringify(response.data));
    //     },
    //     (err) => {
    //       console.log(err);
    //     }
    // );

  },

}
</script>

<style scoped>

</style>