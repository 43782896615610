<template>
  <v-card class="my-2" elevation="0">
    <v-card-text v-if="false">
      <v-row>
        <v-col cols="12" md="6" sm="6">
          <v-img
            height="300px"
            src="./assets/images/silayak-dashboard-1.png"
            contain
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-img
            height="300px"
            src="./assets/images/silayak-dashboard-2.png"
            contain
          >
          </v-img>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card class="ma-4" elevation="0">
      <InfografikBasic></InfografikBasic>
    </v-card>
    <v-card class="ma-4" elevation="0" v-if="false">
      <ChartBasic></ChartBasic>
    </v-card>
  </v-card>
</template>
  
  <script>
import InfografikBasic from "@/components/admin_infografik/InfografikBasic.vue";
import ChartBasic from "@/components/admin_infografik/ChartBasic.vue";

export default {
  name: "InfografikBasicView",
  components: {
    ChartBasic,
    InfografikBasic,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
  
  <style scoped>
</style>