export const registration = {
  namespaced: true,
  state: {
    secretKeyDummy: 'secret Key Dummy',
    tokenOtp: '',
    tokenExpired: 0,
    whatsAppNumber: '',
    namaLengkap: '',
    itemFtReg: JSON.parse(localStorage.getItem('itemFtReg')) || {},
  },
  actions: {
    setTokenOtp({commit}, payload){
      commit('mutateTokenOtp', payload)
    },
    setTokenExpired({commit}, payload){
      commit('mutateTokenExpired', payload)
    },
    setWhatsAppNumber({commit}, payload){
      commit('mutateWhatsAppNumber', payload)
    },
    setNamaLengkap({commit}, payload){
      commit('mutateNamaLengkap', payload)
    },
    setItemFtReg({commit}, payload){
      commit('mutateItemFtReg', payload)
    },

  },
  mutations: {
    mutateTokenOtp(state, payLoad){
      state.tokenOtp = payLoad
    },
    mutateTokenExpired(state, payLoad){
      state.tokenExpired = payLoad
    },
    mutateWhatsAppNumber(state, payLoad){
      state.whatsAppNumber = payLoad
    },
    mutateNamaLengkap(state, payLoad){
      state.namaLengkap = payLoad
    },
    mutateItemFtReg(state, payLoad){
      state.itemFtReg = payLoad
      localStorage.setItem('itemFtReg', JSON.stringify(state.itemFtReg))
    },


  },

}
