
<template>
  <div>
    <v-card class="elevation-0 mt-4 service bg-light section text-xs-center">
      <v-card-title class="text-h4 mb-6 font-weight-black">
        <v-row justify="center">
          LAYANAN
        </v-row>
      </v-card-title>
      <v-card-text class="font-weight-bold text-subtitle-2">
        <v-row justify="center">
          <span>Untuk dapat mengakses layanan silahkan melakukan</span>
          <v-btn x-small color="primary" class="white--text mx-2" @click="toLogin">Login</v-btn>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row class="py-4" v-if="itemsFTemplateReg.length < 1" align="center">
          <v-col cols="12" align="center">
          <span>
             Data Tidak Tersedia
            </span>
          </v-col>
        </v-row>

        <v-layout wrap class="d-flex mt-2 justify-center">
          <div v-for="(item, i) in itemsFTemplateReg" :key="i">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                    width="200"
                    height="225"
                    xs12
                    sm4
                    px-4
                    class="ma-3 rounded-lg"
                    :elevation="hover ? 6 : 6"
                >
                  <div>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-img
                            width="200"
                            height="160"
                            :src="lookupImageUrl(item)"
                            :class="{ 'hover-image': hover }"
                            class="rounded-lg">
                        </v-img>
                      </template>
                    </v-hover>
                  </div>


                  <v-card-text>
                    <div class="subtitle-2 font-weight-bold text-center">
                      {{ item.description }}
                    </div>
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </div>
        </v-layout>

      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import FileService from "@/services/apiservices/file-service";

export default {
  name: "LayananTable",
  props: {
    itemsFTemplateReg: Array
  },
  components: {

  },
  data: () => ({
  }),
  methods: {
    toLogin(){
      this.$router.push("/login")
    },
    linkNeated(link) {
      return link.replace(/\s+/g, "-").toLocaleLowerCase();
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },

};
</script>

<style scoped>
</style>