<template>
  <div class="mt-0 py-2">
    <div class="font-weight-black text-h6 mt-0 mb-2 ml-4">
      REKAPITULASI LAYANAN
    </div>

    <v-data-table
      :headers="headers"
      :items="ftRegistrasisFiltered"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:[`item.ftemplateRegBean`]="{ item }">
        <div class="font-weight-bold">
          {{ lookupItemTemplateReg(item.ftemplateRegBean).description }}
        </div>
      </template>

      <template v-slot:[`item.submitted`]="{}">
        <v-btn
          plain
          fab
          dark
          text
          color="yellow darken-4"
          class="font-weight-black"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.prosesVerifikasi`]="{}">
        <v-btn plain fab dark text color="primary" class="font-weight-black"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.ditolakBts`]="{}">
        <v-btn
          plain
          fab
          dark
          text
          color="red"
          class="font-weight-black pa-0 ma-0"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.ditolakTms`]="{}">
        <v-btn plain fab dark text color="red" class="font-weight-black"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.approved`]="{}">
        <v-btn plain fab text color="success" class="font-weight-black"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.dokumenValid`]="{}">
        <v-btn plain fab text color="success" class="font-weight-black"
          ></v-btn
        >
      </template>
      <template v-slot:[`item.totalPengajuan`]="{ item }">
        <v-btn plain fab text class="font-weight-black">{{
          item.totalPengajuan
        }}</v-btn>
      </template>
    </v-data-table>
    <!-- {{ itemsFTemplateReg }} -->
  </div>
</template>
  
<script>

import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";
import FtRegistrasi from "@/models/ft-registrasi";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";

export default {
  name: "InfografikBasic",
  data() {
    return {
      headers: [
        {
          text: "Jenis Layanan",
          align: "start",
          sortable: false,
          value: "ftemplateRegBean",
        },
        {
          text: "Menunggu Verifikasi",
          align: "center",
          sortable: false,
          value: "submitted",
        },
        {
          text: "Proses Verifikasi",
          align: "center",
          sortable: false,
          value: "prosesVerifikasi",
        },
        {
          text: "Berkas Tidak Sesuai",
          align: "center",
          sortable: false,
          value: "ditolakBts",
        },
        {
          text: "Tidak Memenuhi Syarat",
          align: "center",
          sortable: false,
          value: "ditolakTms",
        },
        {
          text: "Berkas Valid",
          align: "center",
          sortable: false,
          value: "dokumenValid",
        },
        // {
        //   text: "Approved",
        //   align: "center",
        //   sortable: false,
        //   value: "approved",
        // },
        {
          text: "Total Pengajuan",
          align: "center",
          sortable: false,
          value: "totalPengajuan",
        },
      ],
      ftRegistrasis: [new FtRegistrasi()],
      itemsFTemplateReg: [],
    };
  },
  computed: {
    transformedData() {
      // Your data transformation logic here
      const result = this.data.reduce((acc, item) => {
        const { ftemp, status } = item;
        acc[ftemp] = acc[ftemp] || {
          ftemp,
          count: 0,
          waiting: 0,
          accepted: 0,
          rejected: 0,
        };
        acc[ftemp].count++;

        switch (status) {
          case 0:
            acc[ftemp].waiting++;
            break;
          case 1:
            acc[ftemp].accepted++;
            break;
          case 2:
            acc[ftemp].rejected++;
            break;
          default:
            break;
        }

        return acc;
      }, {});

      return Object.values(result);
    },
    ftRegistrasisFiltered() {
      // Your data transformation logic here
      let filtered = this.ftRegistrasis.filter(
        (item) => item.submitted === true
      );
      const mapping = filtered.reduce((acc, item) => {
        const { ftemplateRegBean } = item;
        acc[ftemplateRegBean] = (acc[ftemplateRegBean] || 0) + 1;
        return acc;
      }, {});

      return Object.keys(mapping).map((ftemplateRegBean) => ({
        ftemplateRegBean: parseInt(ftemplateRegBean), // Convert ftemplateRegBean to integer if needed
        totalPengajuan: mapping[ftemplateRegBean],
      }));
    },
  },
  methods: {
    fetchFtRegistrasi() {
      FtRegistrasiService.getAllFtRegistrasi().then(
        (response) => {
          this.ftRegistrasis = response.data;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    fetchParent() {
      FTemplateRegService.getAllFTemplateReg().then(
        (response) => {
          this.itemsFTemplateReg = response.data;
        },
        (err) => {
          console.log(err);
        }
      );
    },
    lookupItemTemplateReg(fTemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === fTemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

  },
  mounted() {
    this.fetchFtRegistrasi();
    this.fetchParent();


  },
};
</script>
  
  <style scoped>
</style>